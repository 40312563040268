<script>
import Illness from "./point-content/Illness.vue";
import DataTable from "./point-content/DataTable.vue";
import DataCollapseTable from "./point-content/DataCollapseTable.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {DataCollapseTable, DataTable, Illness },
  setup(props){
    const searchSettings = window.searchForm.GetSettings();
    return {
      groups: window.searchResults.GetPointGroupsForProducts(props.item.point_products),
      searchSettings
    };
  },
  computed: {
    getSortedGroups(){
      return Object.values(this.groups)
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => a.level - b.level)
    }
  }
};
</script>

<template>
  <div class="resultCardContent">
    <div class="resultCardContent__description">
      <p v-if="item.telefon && item.telefon !== 'brak'"><a :href="'tel:'+item.telefon">{{item.telefon}}</a></p>
      <p v-html="item.godziny_otwarcia"></p>
    </div>
    <Illness v-if="searchSettings.settings.content_type === 'params-list'" v-for="group in getSortedGroups" :products="group.products" :title="group.name"/>
    <DataTable v-if="searchSettings.settings.content_type === 'products-list'" v-for="group in getSortedGroups" :products="group.products" :title="group.name" />
    <DataCollapseTable v-if="searchSettings.settings.content_type === 'products-collapse-list'" v-for="group in getSortedGroups" :products="group.products" :title="group.name" />
  </div>
</template>

<style scoped lang="scss">
.resultCardContent {
  padding-top: 29px;
  padding-bottom: 10px;
  padding-left: 59px;

  @media (max-width: 992px){
    padding-top: 0;
    padding-left: 0;
  }

  &__description {
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: var(--gray-50);
  }

  &__count {
    font-weight: 700;
    margin-top: 30px;
  }
}
</style>
