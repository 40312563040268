<script>
import { ref } from 'vue';
import NoData from "../sections/NoData.vue";
import ResultCard from "./ResultCard.vue";
import SearchResultContent from "./SearchResultContent.vue";
import CardHeader from "./CardHeader.vue";
import { Collapse } from 'vue-collapsed';
import CloseIcon from "../assets/close.svg";
import axios from "axios";
import ApiToken from "../modules/ApiToken.js";

export default {
  props: {
    items: {
      type: Object,
      required: true
    }
  },
  components: {CloseIcon, CardHeader, SearchResultContent, ResultCard, NoData, Collapse },
  setup(props) {
    const points = Object.values(props.items.points).map((item) => ({
      id: item.point.id,
      lat: item.point.lat,
      lng: item.point.lng,
      primaryColor: window.illness_search.primary_color,
      nazwa: item.point.nazwa,
      ulica: item.point.ulica,
      kod_pocztowy: item.point.kod_pocztowy,
      miasto: item.point.miasto,
      telefon: item.point.telefon,
      email: item.point.email,
      godziny_otwarcia: item.point.godziny_otwarcia,
      distance: item.distance,
      hierarchy_point: item.how_many_points,
      result_count: item.point_result_counter,
      total_result_count: Object.values(props.items.parameters).length,
      point_products: item.point_products,
    }));
    points
        .sort((a, b) => a.distance - b.distance)
        .sort((a, b) => a.hierarchy_point.toString().localeCompare(b.hierarchy_point.toString()));

    return {
      points,
      pdfForm: ref(null)
    };
  },
  data(){
    return {
      activeCard: [],
      pdfData: {},
      pdfActive: false,
      pdfAction: '/wp-admin/admin-ajax.php?action=send_mail_pdf',
      pdfError: false,
      pdfConfirm: false
    }
  },
  methods: {
    setActiveCard(index){
      this.pdfActive = false;
      if(this.activeCard.includes(index)) this.activeCard.splice(this.activeCard.indexOf(index), 1);
      else this.activeCard.push(index);
    },
    generatePDF(type, item){
      const actions = {
        download: '/wp-admin/admin-ajax.php?action=create_pdf',
        email: '/wp-admin/admin-ajax.php?action=send_mail_pdf'
      }
      const groups = window.searchResults.GetPointGroupsForProducts(item.point_products);
      this.pdfData = JSON.stringify({
        id: item.id,
        color: window.illness_search.primary_color.replace('#', ''),
        contentType: window.searchForm.GetSettings().settings.content_type,
        title: item.nazwa,
        city: item.miasto,
        address: item.ulica,
        postcode: item.kod_pocztowy,
        phone: item.telefon,
        openHours: item.godziny_otwarcia,
        products: Object.values(groups)
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => a.level - b.level)
      });
      setTimeout(() => {
        this.pdfActive = false;
        if(this.pdfForm){
          this.pdfAction = actions[type];
          if(type === 'download') {
            this.$emit('send-analytics', 'pdf', item.id);
            this.pdfForm.submit();
            return;
          }

          this.pdfActive = true;
        }
      }, 300);
    },
    closeEmailForm(){
      this.pdfActive = false;
    },
    validateEmail(email){
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    submitForm(e){
      if(this.pdfActive){
        e.preventDefault();

        const email = e.target.querySelector('input[name=email]').value;
        this.pdfError = email.length === 0 || !this.validateEmail(email);

        if(!this.pdfError){
          const currentPoint = JSON.parse(this.pdfData);
          this.$emit('send-analytics', 'email', currentPoint.id);
          this.pdfConfirm = false;
          this.pdfForm.submit();
          setTimeout(() => {
            this.pdfConfirm = true;
          }, 500);
        }
      }
    }
  }
};
</script>

<template>
  <div class="search-result-list">
    <div v-if="points.length === 0"><NoData/></div>
    <ResultCard v-if="points.length > 0" v-for="(item, index) in points" :is-open="activeCard.includes(index)">
      <CardHeader :index="index + 1" :item="item" @click="setActiveCard(index, item)" :is-open="activeCard.includes(index)" v-on:create-pdf="generatePDF($event, item)"/>
      <Collapse :when="activeCard.includes(index)">
        <SearchResultContent :item="item" />
      </Collapse>
    </ResultCard>
    <form ref="pdfForm" target="_blank" :action="pdfAction" method="post" :class="{active: pdfActive}" @submit="submitForm">
      <div class="formWrapper">
        <CloseIcon class="close" @click="closeEmailForm"/>
        <label for="email">Wprowadź adres email:</label>
        <input type="email" name="email" placeholder="email@laboratorium.info.pl">
        <span v-if="pdfError">Adres e-mail jest nieprawidłowy.</span>
        <span v-if="pdfConfirm" class="confirm">Email został wysłany.</span>
        <button>Wyślij</button>
        <input type="hidden" name="item" :value="pdfData">
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.search-result-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: var(--width-main);
  width: 100%;
  margin: 0 auto;

  @media (max-width: 992px){
    padding: 0 16px;
  }

  > form{
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s ease, visibility .3s ease;

    .formWrapper{
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 500px;
      padding: 32px;
      background-color: var(--white);
      border-radius: 20px;
      position: relative;
    }

    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(var(--primary-rgb), .1);
      border-radius: 20px;
      border: 1px solid var(--gray-20);
      z-index: -1;
    }

    .close{
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    input{
      padding: 12px 16px;
      border-radius: 20px;
      border: none;
      outline: none;
      background-color: var(--gray-10);
    }

    span{
      color: #f00;

      &.confirm{
        color: var(--primary);
      }
    }

    button{
      width: 100%;
      padding: 12px;
      border-radius: 20px;
      color: var(--white);
      background-color: var(--primary);
    }

    &.active{
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
